define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-authorization", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-authorization">
    <a
      href={{@authorization.actor_id}}
      class="activity-pub-authorization-link btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{d-icon "external-link-alt"}}
      {{@authorization.actor_id}}
    </a>
    <DButton
      @icon="times"
      @action={{action "remove"}}
      @title="user.discourse_activity_pub.remove_authorization_button.title"
      id="user_activity_pub_authorize_remove_authorization"
      class="activity-pub-authorize-remove-authorization"
    />
  </div>
  */
  {
    "id": "froer4uV",
    "block": "[[[10,0],[14,0,\"activity-pub-authorization\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,1,[\"actor_id\"]]],[14,0,\"activity-pub-authorization-link btn\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"external-link-alt\"],null]],[1,\"\\n    \"],[1,[30,1,[\"actor_id\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,1,\"user_activity_pub_authorize_remove_authorization\"],[24,0,\"activity-pub-authorize-remove-authorization\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[28,[37,2],[[30,0],\"remove\"],null],\"user.discourse_activity_pub.remove_authorization_button.title\"]],null],[1,\"\\n\"],[13]],[\"@authorization\"],false,[\"d-icon\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-authorization.hbs",
    "isStrictMode": false
  });
  let ActivityPubAuthorization = _exports.default = (_class = class ActivityPubAuthorization extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
    }
    remove() {
      const actorId = this.args.authorization.actor_id;
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("user.discourse_activity_pub.authorization.confirm_remove", {
          actor_id: actorId
        }),
        didConfirm: () => {
          this.args.remove(actorId);
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubAuthorization);
});